import {Link} from "gatsby"
import React from "react"
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import styles from "./FeatureCard.css";
import Image from "./Image";

const FeatureCard = ({ title, subtitle, description, image, imageLeft, url, urlText, iconlinks, currentLanguage, isButtonVisible, clickHandler }) => {
    return (
        <div className="feature-card">
            {imageLeft ? <div className="card-container dark">
                    <Grid container>
                        <Grid item lg={7} xs={12}>
                            {
                                clickHandler ? image && <Image onClick={clickHandler}  className="card-image" image={image}/>
                                    :   <Link className="card-url" to={url}>
                                        {image && <Image className="card-image" image={image}/>}
                                    </Link>
                            }

                        </Grid>
                        <Grid item lg={5} xs={12} className="feature-card-content">
                            <div className="card-subtitle">{subtitle}</div>
                            {
                                clickHandler ? <div onClick={clickHandler} className="card-title title-big">{title}</div>
                                    :   <Link className="card-url" to={url}>
                                        <div className="card-title title-big">{title}</div>
                                    </Link>
                            }

                            <div className="card-description">{description}</div>
                            <br/>
                            {
                                isButtonVisible && <Link className="card-url" to={url}>
                                    <Button variant="contained" size="medium" color="primary">
                                        {urlText}
                                    </Button>
                                </Link>
                            }
                        </Grid>
                    </Grid>
                </div>
                : <div className="card-container dark">
                    <Grid container>
                        <Hidden lgUp>
                            <Grid item xs={12}>
                                {
                                    clickHandler ? image && <Image onClick={clickHandler} className={styles.cardImage} image={image}/>
                                        :   <Link to="{url}">
                                            {image && <Image className={styles.cardImage} image={image}/>}
                                        </Link>
                                }
                            </Grid>
                        </Hidden>
                        <Grid item lg={5} xs={12} className="feature-card-content">
                            <div className="card-subtitle">{subtitle}</div>
                            {
                                clickHandler ? <div onClick={clickHandler} className="card-title title-big">{title}</div>
                                    :   <Link className="card-url" to={url}>
                                        <div className="card-title title-big">{title}</div>
                                    </Link>
                            }
                            <div className="card-description">{description}</div>
                            <br/>
                            {
                                isButtonVisible && <Link to={url}>
                                    <Button variant="contained" size="medium" color="primary">
                                        {urlText}
                                    </Button>
                                </Link>
                            }
                        </Grid>
                        <Hidden mdDown>
                            <Grid item lg={7}>
                                {
                                    clickHandler ? image && <Image onClick={clickHandler} className="card-image" image={image}/>
                                        :   <Link to={url}>
                                            {image && <Image className="card-image" image={image}/>}
                                        </Link>
                                }
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>
            }
        </div>
    );
}
export default FeatureCard