/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {graphql, Link} from 'gatsby';
import Layout from '../components/layout';
import Slider from 'react-slick';
import About from '../components/About';
import Image from "../components/Image";
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import '../components/home.css';
import FeatureCard from '../components/FeatureCard'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
/*import Insta from "../components/Insta";*/
import {Grid, Hidden} from "@material-ui/core";
import SEO from "../components/SEO";
import Contact from "../components/Contact";
import {OutboundLink} from "gatsby-plugin-google-analytics"


const sliderSettings = {
	dots: true,
	lazyLoad: true,
	infinite: true,
	swipeToSlide: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	arrows: true,
	fade: true
};

const sponsorSliderSettings = {
	infinite: true,
	variableWidth: true,
	arrows: true,
	adaptiveHeight: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	initialSlide:  Math.floor( Math.random() * 4 ),
	responsive: [
		{
			breakpoint: 1023,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				variableWidth: false,
				adaptiveHeight: false,
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
				adaptiveHeight: false,
				autoplay: true,
				autoplaySpeed: 4000,
				arrows: true,
				fade: true
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
				adaptiveHeight: false,
				autoplay: true,
				autoplaySpeed: 4000,
				arrows: true,
				fade: true
			}
		}]
};

export default class Home extends React.Component {
	render() {
		const currentPage = this.props.data.wordpressPage;
		return (
			<Layout id="index">
				<SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
					 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
				/>
				<span id="home">
					<Slider {...sliderSettings}>
						{currentPage.acf.slider_page.map((slide) => {
							return (
								<div className="slide" key={slide.title}>
									{slide.image &&  slide.image.localFile && <Image image={slide.image} />}
									<div className="slide-content">
										<h1 className="slide-description">{slide.description}</h1>
										<Link to={slide.link}>
											<Button variant="contained"  key={<slide className="title" />} className="slide-more-button" size="small" color="primary">
												{slide.link_text}
											</Button>
										</Link>
									</div>
								</div>
							);
						})}
					</Slider>

					<About id="Teams0_0"
						   key="Teams0_0"
						   img={currentPage.acf.aboutimage}
						   title={currentPage.acf.abouttitle}
						   content={currentPage.acf.aboutdescription}
					/>
					{
						currentPage.acf.offers_page.map((offer, index) =>
							<FeatureCard
								id={`Content1_0_${offer.title}`}
								url={offer.link}
								key={`Content1_0_${offer.title}`}
								title={offer.title}
								imageLeft={index % 2}
								description={offer.description}
								image={offer.image}
							/>
						)
					}

					<Contact title={this.props.data.wordpressPage.acf.contact.title}
							 image={this.props.data.wordpressPage.acf.contact.image}
							 description={this.props.data.wordpressPage.acf.contact.description} />

					<br />
					<Grid container className="cards" justify="space-evenly">
						{currentPage.acf.cards_page.map((card, i) => {
							return <Grid item lg={4} xs={11} key={`card_${i}`} style={{marginBottom: '1em'}}>
								<Link to={card.link}>
									<Card className="card">
										{
											card.image && <Image image={card.image} />
										}
										<CardContent style={{padding: '1em'}}>
											<h3 className='title-big'>
												{card.title}
											</h3>
											{card.description}
										</CardContent>
									</Card>
								</Link>
							</Grid>

						})}
					</Grid>

                    <div className='dark-hero-content' style={{marginTop: "1em"}}>
                        <h2 className='title-big'>Sponsoren</h2>
                    </div>
					<Hidden mdUp>
						<div className='sponsors'>
								<Slider {...sponsorSliderSettings}>
									{
										currentPage.acf.sponsors_page.map((card, i) => {
											return (
												<div key={`sponsor_${i}`} style={{margin: '1em'}}>
													<OutboundLink target='_blank' href={card.link}>
														<Card className="sponsor-card">
															<Image className="sponsor-card-image" image={card.image} />
															<CardContent className="sponsor-card-content">
																<h3 className='title-big'>
																	{card.title}
																</h3>
																{card.description}
															</CardContent>
														</Card>
													</OutboundLink>
												</div>
											)
										})
									}
								</Slider>
						</div>
					</Hidden>
					<Hidden smDown>
						<Grid container className='sponsors' justify='center'>
							{
								currentPage.acf.sponsors_page.map((card, i) => {
									return (
										<Grid item key={`sponsor_${i}`} style={{margin: '1em'}}>
											<OutboundLink href={card.link} target='_blank'>
												<Card className="sponsor-card">
													<Image className="sponsor-card-image" image={card.image} />
													<CardContent className="sponsor-card-content">
														<h3 className='title-big'>
															{card.title}
														</h3>
														{card.description}
													</CardContent>
												</Card>
											</OutboundLink>
										</Grid>
									)
								})
							}
						</Grid>
					</Hidden>
				</span>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query {
		wordpressPage(slug: { eq: "home" }) {
			title
			date(formatString: "MMMM DD, YYYY")
			yoast_meta {
				yoast_wpseo_title
				yoast_wpseo_metadesc
			}
			acf {
				slider_page {
					title
					description
					link
					link_text
					image {
						localFile {
							childImageSharp {
								id
								fluid {
									base64
									tracedSVG
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									originalName
								}
							}
						}
					}
				}
				sponsors_page {
					title
					description
					link
					image {
						localFile {
							childImageSharp {
								id
								fluid {
									base64
									tracedSVG
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									originalName
								}
							}
						}
					}
				}
				contact {
					title
					description
					image {
						localFile {
							childImageSharp {
								id
								fluid {
									base64
									tracedSVG
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									originalName
								}
							}
						}
					}
				}
				aboutdescription
				abouttitle
				aboutimage {
					id
					source_url
					localFile {
						childImageSharp {
							id
							fluid {
								base64
								tracedSVG
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								originalName
							}
						}
					}
				}
				offers_page {
					id
					title
					link
					description
					imageposition
					image {
						localFile {
							childImageSharp {
								id
								fluid {
									base64
									tracedSVG
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									originalName
								}
							}
						}
					}
				}
				cards_page {
					description
					title
					link
					image {
						localFile {
							childImageSharp {
								id
								fluid {
									base64
									tracedSVG
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									originalName
								}
							}
						}
					}
				}
				slider_page {
					image {
						localFile {
							childImageSharp {
								id
								fluid {
									base64
									tracedSVG
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									originalName
								}
							}
						}
					}
				}
			}
		}
	}
`;
