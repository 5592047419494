import React from 'react';
import 'rc-banner-anim/assets/index.css';
import '../components/styles/about.css'
import Image from "./Image";

class About extends React.PureComponent {
  render() {
    const { ...tagProps } = this.props;
    const { img, content, title } = tagProps;

    return (
      <div {...tagProps} className='about-container'>
          <h3 className='about-title'>{title}</h3>
          <div className='about-image-content-wrapper'>
              {img && <Image class='about-image' image={img} />}
              <div className='about-content' dangerouslySetInnerHTML={{
                  __html: content
              }}></div>
          </div>
      </div>
    );
  }
}

export default About;
